.container {
  display: flex !important;
  flex-direction: row !important;
  width: 99.6%;
  margin: 0px !important;
  padding: 4px !important;
}
.no_spacing {
  margin: 0px !important;
}
.button {
  color: rgb(255, 255, 255) !important;
  margin: 0px 5px !important;
}
