.text {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.label {
  color: gray;
}
.root {
  position: relative;
}
.editContainer {
  position: absolute;
  right: 20px;
  text-align: right !important;
}
