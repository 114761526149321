.render{
    display: flex;
    justify-content: center;
    align-items: center;
}
.currentPageButton{
    color: #fff;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 50px;
    border-radius:10px;
    margin-right: 10px;
    background-color: #6872DD;
    }
    .nextPageButton{
        color: #000;
        font-size: 22px;
        border-width: 0;
        width: 250px;
        height: 50px;
        margin-right: 10px;
        background-color: #D9D7DA;
        border-radius:10px;
    }