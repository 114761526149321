.chpterStatBox{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000A;
    border-radius: 4px;
    opacity: 1;
    height: 133px;
  }
  
  .statText{
    text-align: left;
    font: normal normal 600 35px/40px Poppins;
    letter-spacing: 0px;
    color: #4D4F5C;
    opacity: 1;
  }

  .copySvg{
    font-size: 3rem !important;
  }

  .headerText{
    text-align: left;
    font: normal normal normal 28px/40px Roboto;
    letter-spacing: 0px;
    color: #43425D;
    opacity: 1;
    padding-left: 20px;
  }
  
  .search {
    width: 100%;
    position: relative;
    display: flex;
    border-radius: 25px;
  }
  
  .searchTerm {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 26px 0 0 26px;
    outline: none;
    color: #9DBFAF;
    height: 46px;
    background: #F7F7FB;
    text-align: center;
  }
  
  .searchTerm:focus{
    color: gray;
  }
  
  .searchButton {
    width: 55px;
    height: 46px;
    border: 1px solid #CCCCCC;
    background: #DADAE0;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0 26px 26px 0;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    padding: 20px;
  }

  .inputWrapper{
    display: "flex";
    width: "100%";
    flex-wrap: "wrap";
    justify-content: "space-between";
  }

  .filterActions{
    justify-content: flex-end
  }

  .filterOriginal{
    background-color : #000;
    background: url("../../assets/img/filter_icon.svg") no-repeat center / contain;
  }

  .filterOpened{
    background-color: #ffffff; /* defines the background color of the image */
    mask: url("../../assets/img/filter_icon.svg") no-repeat center / contain;
    -webkit-mask: url("../../assets/img/filter_icon.svg") no-repeat center / contain;
  }

  .filterIcon{
    height: 24px;
    width: 24px;
  }