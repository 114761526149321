.header{
    text-align: center; 
}

.headSection{
    display: flex;
    justify-content: space-between;
}

.modalHead{
    display: flex;
    justify-content: space-between;
    font-weight: 800;
}

.formFields{
    display: flex;
    align-items: center;
}

.formHead{
    font-weight: 800;
}

.center{
    justify-content: center;
    font-weight: 800;
}

.imgPreview{
    height: 150px;
    width: 250px;
}

#imgPrev{
    transition: transform .2s; /* Animation */
}

#imgPrev:hover{
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
}
.currentPageButton{
color: white;
font-size: 22px;
border-width: 0;
width: 250px;
height: 100px;
border-radius:10px;
margin-right: 10px;
background-color: #0DB41E;
}
.nextPageButton{
    color: #4B68BA;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 100px;
    margin-right: 10px;
    background-color: #EEF7FE;
    border-radius:10px;
}
.PhotoContainer{
    background-color: #ECFBFF;
    margin-top: 20px;
    padding: 30px;
}
.sliderText{
    font-size: 26px;
    font-weight: 600px;
    color: #000000;
}
.PhotoSubContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.sliderImage{
    /* width: 600px; */
    /* height: 400px;
    background-size: cover; */
    max-width:500px;
    height:350px;
    width: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    /* height: auto; */
  
}
.sliderButton{
    font-size: 36px;
    border:0;
    color: #C55B5B;
    background-color: #ECFBFF;
    
}
.typeOfImage{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.imageTypeText{
    font-size: 20px;
    color: black;
   margin: 10px;
}
.redText{
    color: red;
}
.redTextMiddle{
    color: red;
    margin-left: 360px;
}
.redTextMiddleSlider{
    text-align: center;
    color: red;
}
.imageTypeTextActive{
    color: #7F21B8;
}
.headMasterText{
    color: #958989;
    font-size: 26px;
    min-width: 400px;
    margin-top: 10px;

}
.imageHeadingText{
    color: #958989;
    /* font-size: 26px; */
    width: 250px;
    margin-top: 10px;
    /* margin-right: 20px; */
}
.headMasterData{
    display: flex;
    flex-direction: row;

}
.headMasteContainer{
    display: flex;
flex-direction: row;
align-items: center ;
}
.vidaythiSankhayaContainer{
    display: flex;
flex-direction: row;   
}
.inputClass{
border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
}
.nameText{
 color: #415EB6;
 font-size: 16px;
 margin-top: 14px;
}
.nameTextContainer{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
    .addressTextContainer{
        border:0;
        background-color: #DFE4E8;  
        height: 80px;
        margin-bottom: 5px;
        margin-right: 10px; 
        margin-left: 10px;  
        padding-left: 10px;
        width: 330px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }
.schoolStudentsTotal{
    border:0;
    background-color: #DFE4E8;  
    height: 200px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 175px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 25px;
}
.schoolStudentsTotalText{
    font-size: 30px;
    color: #415EB6;
}
.schoolStudentsTotaBoy{
    border:0;
    background-color: #DFE4E8;  
    height: 95px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    margin-bottom: 10px;
    width: 200px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}
.vidaythiSankhayaMiddle{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.schoolStudetsInput{
    border:0;
    background-color: #DFE4E8; 
    height: 45px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 100px;

}
.inputClassBoy{
    border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
margin-bottom: 10px;
}
.inputClassTotal{
    border:0;
    background-color: #DFE4E8;  
    height: 90px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
  
}
.inputClassCount{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
    margin-top: 5px;
}
.submitButtonContainer{
    display: flex;   
justify-content: center;
margin-bottom: 40px;
}

.submitButton{
    background-color: #B3BBB3;
    border-radius: 10px;
    color: black;
    width: 200px;
    border: none;
    height: 40px;
    margin: 10px;
}
.committeeContainer{
    display: flex;
    flex-direction: "row";
    align-items: "center";
    margin-top:20px;
}
.committeeNameInput{
color:#415EB6;
border:0;
background-color: #DFE4E8;
width:220px;
height:46px;
margin-left: 30px;
margin-right:20px;
padding-left: 10px;
}
.committeeGenderInput{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:200px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.committeeCategoryInput{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:200px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.teacherCasteSelect{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:100px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.committeeMobileInput{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:150px;
    height:46px;
    padding-left: 10px;
        margin-right:20px;
}
.committeeDateInput{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    min-width:150px;
    height:46px;
    margin-right:20px;
    margin-left: 30px;
    padding-left: 10px;
}
.committeeAttendanceInput{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:150px;
    height:46px;
    padding-left: 10px;
    margin-right:20px;
}
.committeeDetailsInput{

    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:350px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;
}
.committeeDetailsMeetings{

    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    max-width:200px;
    
    height:46px;
    margin-right:10px;
    padding-left: 10px;
}

.StudentsAndClassContainer{
    display: flex;
    flex-direction: row;

}
.nameTextContainerClass{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 165px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    
}
.nameTextContainerClass1{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 130px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
    
}
.nameTextClass{
    color: #415EB6;
    font-size: 16px;
    margin-top: 14px;
}

.redTextMiddleClass{
    color: red;
    margin-left: 10px;
}
.redStar{
    margin-left: 5px;
    color:red;
    margin-right: 5px;
    
}
.uploadText{
    color:#415EB6;
    font-size: 13px;
}
.reminderContainer{
    display: flex;
    flex-direction: column;

}
.remiderTextPrathmik{
    color:#958989;
    font-size: 12px;
}
.imageContainer{
    display: flex;
    margin-top: 10px;
    flex-direction: row;
}
.competitiveExamText{
    min-width: 250px;
    padding: 10px;
    color:#958989 ;
}
.uniformText{
    min-width: 350px;
    padding: 10px; 
    color: #958989;  
}
.teacherCaste{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:101px;
    height:46px;
    margin-right:20px;
    padding-left: 10px;   
}
.schoolStudentsTotal::placeholder{
font-size:14px
}
.schoolStudetsInput::placeholder{
    font-size: 10.5px;
}
.teachersNumberPlaceHolder::placeholder{
    font-size: 13px;
}
.fileNameText{
    font-size: 10px;
    margin-left: 6px;
}