.header{
    text-align: center; 
}

.headSection{
    display: flex;
    justify-content: space-between;
}

.modalHead{
    display: flex;
    justify-content: space-between;
    font-weight: 800;
}

.formFields{
    display: flex;
    align-items: center;
}

.formHead{
    font-weight: 800;
    font-size:20px;
}

.center{
    justify-content: center;
    font-weight: 800;
}

.imgPreview{
    /* height: 150px; */
    width: 250px;
}

#imgPrev{
    transition: transform .2s; /* Animation */
}

#imgPrev:hover{
    -ms-transform: scale(1.5); /* IE 9 */
    -webkit-transform: scale(1.5); /* Safari 3-8 */
    transform: scale(1.5); 
}.currentPageButton{
color: white;
font-size: 22px;
border-width: 0;
width: 250px;
height: 100px;
border-radius:10px;
margin-right: 10px;
background-color: #0DB41E;
}
.nextPageButton{
    color: #4B68BA;
    font-size: 22px;
    border-width: 0;
    width: 250px;
    height: 100px;
    margin-right: 10px;
    background-color: #EEF7FE;
    border-radius:10px;
}
.PhotoContainer{
    background-color: #ECFBFF;
    margin-top: 20px;
    padding: 30px;
}
.sliderText{
    font-size: 26px;
    font-weight: 600px;
    color: #958989;
}
.PhotoSubContainer{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    
}
.sliderImage{
    /* width: 600px; */
    /* height: 400px;
    background-size: cover; */
    max-width:500px;
    height:400px;
    width: auto;
    /* height: auto; */
  
}
.sliderButton{
    font-size: 36px;
    border:0;
    color: #C55B5B;
    background-color: #ECFBFF;
    
}
.typeOfImage{
    display: flex;
    justify-content: center;
    margin-top: 10px;
}
.imageTypeText{
    font-size: 20px;
    color: black;
   margin: 4px;
}
.imageTypeTextActive{
    color: #7F21B8;
}
.headMasterText{
    color: #958989;
    font-size: 22px;
    min-width: 350px;
    margin-top: 10px;

}
.headMasterData{
    display: flex;
    flex-direction: row;

}
.headMasteContainer{
    display: flex;
flex-direction: row;
align-items: center ;
}
.inputClass{
border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
}
.nameText{
 color: #415EB6;
 font-size: 16px;
 margin-top: 14px;
}
.nameTextContainer{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 230px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.nameTextContainer1{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 150px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}

.nameTextContainer2{
    border:0;
    /* background-color: #DFE4E8;   */
    height: 40px;
    /* margin-right: 10px;  */
    /* margin-left: 10px;   */
    /* padding-left: 10px; */
    width: 270px;
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.schoolStudentsTotal{
    border:0;
    background-color: #DFE4E8;  
    height: 200px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.schoolStudentsTotalText{
    font-size: 30px;
    color: #415EB6;
}
.schoolStudentsTotaBoy{
    border:0;
    background-color: #DFE4E8;  
    height: 95px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    margin-bottom: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.vidaythiSankhayaMiddle{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;  
    padding-left: 10px;
    width: 330px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.inputClassBoy{
    border:0;
background-color: #DFE4E8;  
height: 40px;
margin-right: 10px; 
margin-left: 10px;
padding-left: 10px;
margin-bottom: 10px;
}
.inputClassTotal{
    border:0;
    background-color: #DFE4E8;  
    height: 90px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
  
}
.inputClassCount{
    border:0;
    background-color: #DFE4E8;  
    height: 40px;
    margin-right: 10px; 
    margin-left: 10px;
    padding-left: 10px;
    margin-bottom: 10px; 
    margin-top: 5px;
}
.submitButtonContainer{
    display: flex;
        
justify-content: center;
}
.submitButton{
    background-color: #B3BBB3;
    border-radius: 10px;
    color: black;
    width: 200px;
    border: none;
    height: 40px;
    margin: 10px;
}

