.container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.form_container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}

.image {
  background: url("./../../assets/img/slider1.webp");
  background-repeat: no-repeat;
  flex: 1;
  background-position: center;
  transform: scaleX(-1);
}

.section {
  padding-top: 180px;
  flex: 1;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card {
  display: flex;
  flex-direction: column;
  padding: 36px;
  width: 50%;
  background: white;
  border-radius: 4px;
  border: 1px solid lightgray;
}
.input {
  margin: 15px 0px !important;
}
.submitBtn {
  width: 100%;
}
.btContainer {
  margin-top: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.hint {
  font-size: 12px;
  color: gray;
  margin-bottom: 18px;
}
.label {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}
.header {
  width: 56%;
  margin-bottom: 20px;
}
.header h3 {
  font-weight: bold;
  font-size: 40px;
  color: #01558b;
  margin: 0px;
}
.sliderWrapper {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.logo {
  width: 185px;
  height: auto;
  position: absolute;
  top: 30px;
  right: 60px;
  object-fit: contain;
}

.sliderContainer {
  padding-top: unset;
  align-self: center;
  align-items: flex-start;
}
