.container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.progressBar {
  width: 90%;
  margin: 0px 24px;
}
