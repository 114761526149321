.contentToolbar {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 48px;
  z-index: 10;
}
.cardBody {
  background-color: aliceblue;
}
.textField {
  border: 1px solid black;
  border-radius: 4px;
  background: white;
}
.preview {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px 15px;
  border: 1px solid gray;
  padding: 10px;
}
.item_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.drag_handle {
  border: 1px solid gray;
  display: flex;
  padding: 8px;
  border-radius: 5px;
}
.audioWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.audioPlayer {
  margin: 0px 36px;
  display: flex;
  justify-content: center;
}
.record {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.chapterDetail span{
  padding-right: 10px;
}

.chapterTitle{
  text-align: left;
  font: normal normal normal 22px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
}

.subjectDetail{
  text-align: left;
  font: normal normal normal 18px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 0.7;
}

.otherChapDetail{
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.detailTitle{
  text-align: left;
  font: normal normal bold 16px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
}

.publishBtn{
  background: var(--unnamed-color-3b86ff) 0% 0% no-repeat padding-box !important;
  background: #3B86FF 0% 0% no-repeat padding-box !important;
  border-radius: 4px;
  opacity: 1;
}

.addWidgetDiv{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  opacity: 1;
  width: 71vw;
  height: 154px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  cursor: pointer;
}
.addWidgetBtn{
  position: relative !important;
  z-index: 1 !important;
  display: none !important;
  align-items: center !important;
  justify-content: center !important;
  left: 25vw;
}

.widgets:hover > .addWidgetBtn{
  display: inline-block !important;
}

.MuiGrid-root > :has(input:focus){
  border:blue;
}