.alert {
  text-align: center;
  padding: 8px;
  color: white;
  border-radius: 4px;
  margin-bottom: 15px;
}

.success {
  background-color: lightgreen;
}

.danger {
  background-color: salmon;
}
