
  .published{
    background: #D0EE7C 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .inProgress{
    background: #FF987E 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .inReview{
    background: #FFD358 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .changesRequired{
    background: #FFCC80 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }
