.inputField{
    color:#415EB6;
    border:0;
    background-color: #DFE4E8;
    width:220px;
    height:46px;
    margin-top: 10px;
   
    }
    .addressTextContainer{
        border:0;
        background-color: #DFE4E8;  
        height: 80px;
       margin-top: 10px;
        width: 430px;
        display: flex;
        flex-direction: row;
        align-items: center;
        
    }