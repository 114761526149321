.search {
    width: 100%;
    position: relative;
    display: flex;
    border-radius: 25px;
  }
  
  .searchTerm {
    width: 100%;
    border: 1px solid #CCCCCC;
    border-right: none;
    padding: 5px;
    height: 20px;
    border-radius: 26px 0 0 26px;
    outline: none;
    color: #9DBFAF;
    height: 46px;
    background: #F7F7FB;
    text-align: center;
  }
  
  .searchTerm:focus{
    color: gray;
  }
  
  .searchButton {
    width: 55px;
    height: 46px;
    border: 1px solid #CCCCCC;
    background: #DADAE0;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    border-radius: 0 26px 26px 0;
  }
  
  /*Resize the wrap to see the search bar change!*/
  .wrap{
    padding: 20px;
  }

  .published{
    background: #D0EE7C 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .inProgress{
    background: #FF987E 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .inReview{
    background: #FFD358 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .changesRequired{
    background: #FFCC80 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
  }

  .statusText{
    color: var(--unnamed-color-333333);
    text-align: center;
    font: normal normal normal 16px/25px Roboto;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
  }
  /* .filterIcon{
    fill: black;
  } */

  .filterOriginal{
    background-color : #000;
    background: url("../../assets/img/filter_icon.svg") no-repeat center / contain;
  }

  .filterOpened{
    background-color: #ffffff; /* defines the background color of the image */
    mask: url("../../assets/img/filter_icon.svg") no-repeat center / contain;
    -webkit-mask: url("../../assets/img/filter_icon.svg") no-repeat center / contain;
  }

  .filterIcon{
    height: 24px;
    width: 24px;
  }