.courtesyContainer {
  position: sticky;
  position: -webkit-sticky;
  top: 55px;
  z-index: 100;
}
.tabBar {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 196px;
  z-index: 10;
}
.toolbarWrapper {
  display: "flex";
  flex-direction: "column";
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 244px;
  z-index: 10;
}
/* .toolbarWrapper button:not([name="span"]){
  display: none;
} */
.actionHeader {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  z-index: 10;
  padding-top: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  background-color: #eeeeee;
}
.saveContainer {
  padding-bottom: 17px;
  background-color: aliceblue;
}
.saveBtn {
  margin-left: 23px !important;
  margin-top: 15px;
}
.label {
  font-size: 12px;
  color: gray;
}
.partField {
  background: white;
  border: 3px solid black;
  border-radius: 4px;
  margin-top: 15px;
}
.deletePartContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}
.deletePartBtn {
  text-transform: capitalize !important;
}
.preview {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px 15px;
  border: 1px solid gray;
  padding: 10px;
}

.item_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.drag_handle {
  border: 1px solid gray;
  display: flex;
  padding: 8px;
  border-radius: 5px;
}
.audioWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.audioPlayer {
  margin: 0px 36px;
  display: flex;
  justify-content: center;
}
.record {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

strong{
  font-weight: 700;
}