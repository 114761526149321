.backgroundSiderBar {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  z-index: 1;
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-position: left !important;
}

.logoWrapper {
  width: 28% !important;
  height: 200px;
  text-align: center;
}

.logoWrapper img {
  width: 52%;
}

.navItem {
  width: 100%;
  /* height: 50px; */
  background-color: #eeeeee;
  /* border-top-left-radius: 9999px;
  border-bottom-left-radius: 9999px; */
  position: relative;
  /* margin: 30px 0px; */
  text-align: center;
}

.listItem {
  width: 100%;
  height: 50px;
  background-color: #eeeeee;
  position: relative;
  text-align: center;
  color: blue;
}

.activeItem {
  color: blue !important;
}

.navItem::before,
.navItem::after {
  content: "";
  width: 0;
  height: 0;
  right: 0;
  position: absolute;
}

.sideBarContainer {
  margin-top: 45px !important;
}

.loginContainer {
  z-index: 4 !important;
  color: #fff !important;
  display: flex !important;
  justify-content: center !important;
  font-family: "Poppins" !important;
  align-items: center !important;
  font-size: large;
}

.titleContainer{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  border-radius: 5px;
  background-color: white;
  width: 20%;
  margin-top: 0.15rem;
  text-align: center;
  cursor: pointer
}