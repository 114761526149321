body {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.linkColor {
    color: #FFFFFF;
}

.cardCategoryWhite {
    color: rgba(255, 255, 255, .62);
    margin: 0;
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 0;
}

.cardTitleWhite {
    color: #FFFFFF;
    margin-top: 0;
    min-height: auto;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    margin-bottom: 3px;
    text-decoration: none;
}

.dropdown {
    width: 45%;
    margin-top: 35px;
}

.cardFooter {
    justify-content: flex-end;
}

.cardBorder {
    box-shadow: none;
}

.paddingC {
    padding: 100px;
}

.width28 {
    /* width: 20rem; */
    width: 80%;
}

.width57 {
    width: 57rem;
}

.width15 {
    width: 15rem;
}

.width16 {
    width: 16rem;
}

.rowLimit {
    width: 10rem;
    height: 2.5rem;
}

.color8A {
    color: #0000008A;

}

.widthDialog {
    width: 1500px;
}

.colorRed {
    color: red;
}

/* for challenge list page */
.inputWrapper {
    /* display: flex;
    justify-content: space-around; */
    padding-top: 10px;
    padding-bottom: 10px;
}

.challengePageTitle {
    font-size: 26px;
    font-weight: 500;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    padding: 10px;
}

.challengeListFilterDiv {
    background-color: #FFFFFF;
    border-radius: 8px;
}

.challengeListFilterDivV2 {
    background-color: #FFFFFF;
    border-radius: 8px;
    padding: 20px 10px 40px 10px; 
    margin: 20px 10px;
}

.challengeListDiv {
    background-color: #FFFFFF;
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    padding: 20px;
    margin: 20px 10px 0 10px;
    min-height: 550px;
}

.backgroundColorGray {
    background-color: #f0f5fa;
    padding: 5px 10px 5px 10px;
    border-radius: 8px;
}

.bgYellow {
    background-color: #FFEEBA;
}

.bgBlue {
    background-color: #B8DAFF;
}

.bgGreen {
    background-color: #A5F1E9;
}

.bgRed {
    background-color: #FEA1A1;
}

.challengeListPaginationDiv {
    background-color: #FFFFFF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 20px 20px 5px 0;
    margin: 0 10px 20px 10px;
}

/* Date time picker */
.dateTimeRangeInputContainer {
    display: flex;
    flex-direction: column;
}

label {
    margin-top: 8px;
    margin-bottom: 4px;
}

input {
    padding: 8px;
    margin-bottom: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

.errorText {
    color: red;
    font-size: 14px;
}

.validityDateErrorMsg {
    min-height: 20px;
    margin-top: 4px;
}

/*Date apply */
.applyButton {
    padding: 10px 15px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .applyButton:hover {
    background-color: #45a049;
  }

.tableCellWidth100px {
    max-width: 100px;
    overflow: auto;
}

.width100 {
    width: 100px;
}

.width110 {
    width: 110px;
}

.width80 {
    max-width: 80px;
}

.width250 {
    width: 250px;
}

.pointerA {
    cursor: pointer;
}

.searchHeight {
   height: 60px;
}

.selectSchool {
    max-height: 200px;
    overflow-y: auto;
}inputWrapper

.showSelectedSchools {
    border-radius: 50px;
    /* background-color: gray; */
    /* color: #45a049; */
    max-width: fit-content;
    min-width: fit-content;
}

/* .bgColorTag {
    background-color: ;
} */

.selectSchoolDropdown {
    /* color: #F4EEEE; */
    color: black;
    /* text-decoration-color: ;
    /* background-color: black; */
    /* text-decoration-color: black;  */

}

.bgColorTag {
    background-color: #F4F5F7;
} 

/* .bgShadowTag {
    box-shadow: #3a1b1b;
    
} */

.paginationDiv{
    background-color: #FFFFFF;
    margin-right: 10px;
    margin-left: 10px;
}

.editBtn {
    min-height: 48px;
    width: 48px;
}

