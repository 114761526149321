.logo {
  width: 200px;
  height: 100px;
  object-fit: contain;
  margin: 0 auto;
}
.container {
  width: 365px;
}
.logoContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.logoText {
  text-align: left;
  font: normal normal normal 43px/52px Roboto;
  letter-spacing: 0px;
  color: #0eafd7;
  text-transform: uppercase;
  opacity: 1;
}
.logoTagLine {
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 5px;
}
.form {
    margin-top: 30px;
    flex-direction: column;
    display:flex;
}
.textInput {
    margin-bottom: 20px !important
}
.signBtn {
    width:100%;
    padding: 2px 0px !important;
    background-color:#C4E3C6 !important;
    font-size: 18px !important; 
    color:#4b4b4b 
}
.signBtn1 {
  width:100%;
  padding: 12px 0px !important;
  background-color:#55D75A !important;
  font-size: 18px !important; 
  color:'#000'
}
.signBtn2 {
  width:100%;
  padding: 12px 0px !important;
  background-color:#B3BBB3 !important;
  font-size: 18px !important; 
  color: #4b4b4b;
}
.label {
    color: #4B4B4B;
    font-size: 16px;
    margin-bottom:10px
}
.dropdown {
    border: 1px solid #bdbdbd;
    padding: 10px;
    border-radius: 4px;
    margin-top: 0px !important;
}
.btContainer {
  margin-top: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
