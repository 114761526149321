.render {
    display: flex;
    justify-content: center;
    align-items: center;
}

/* .mainContainer{
    height: 100vh;
    background-image: url('../../assets/img/welcomePage.png');
    background-repeat: no-repeat;
} */

.infoContainer {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-evenly;
    min-height: 15rem;
    flex: 1;
    padding: 10px;
    gap: 0rem;
    text-align: center;
    width: 33%;
    background-color: #F2A4FF;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.infoDiv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}