
.heading {
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #4B4B4B;
  opacity: 1;
}
.input input {
  width: 3rem !important;
  height: 3rem;
  margin: 0 1rem;
  font-size: 2rem;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.controls {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.input {
  margin-top: 70px;
  display: flex;
  justify-content: center;
}
.resend {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.hint {
  color: gray;
  font-weight: bold;
}
.root {
  margin-top: 10%;
}
.resend a {
  margin-left: 5px;
  cursor: pointer;
  color: #0eafd7;
}

.container {
  display: flex;
  height: 100vh;
  flex-direction: row;
}

.form_container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex: 1;
}

.image {
  background: url("./../../assets/img/slider1.webp");
  background-repeat: no-repeat;
  flex: 1;
  background-position: center;
  transform: scaleX(-1);
}

.section {
  padding-top: 180px;
  flex: 1;
  margin: 0px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.card {
  display: flex;
  flex-direction: column;
  padding: 36px;
  width: 50%;
  background: white;
  border-radius: 4px;
  border: 1px solid lightgray;
}
.input {
  margin: 15px 0px !important;
}
.submitBtn {
  width: 100%;
  background-color: #0eafd7 !important;
  height: 53px;
  font: normal normal normal 18px/27px Poppins !important;
  letter-spacing: 0px !important;
  color: #FFFFFF !important;
  text-transform: uppercase !important;
}
.submitBtn:disabled{
  color: #000;
  background-color:lightgray !important;
}
.btContainer {
  margin-top: 25px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.hint {
  font-size: 12px;
  color: gray;
  margin-bottom: 18px;
}
.label {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}
.header {
  width: 56%;
  margin-bottom: 20px;
}
.header h3 {
  font-weight: bold;
  font-size: 40px;
  color: #01558b;
  margin: 0px;
}
.sliderWrapper {
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
}

.logo {
  width: 221px;
  height: auto;
  /* position: absolute;
  top: 30px;
  right: 60px; */
  object-fit: contain;
}

.sliderContainer {
  padding-top: unset;
  align-self: center;
  align-items: flex-start;
}

.alignCenter{
  display: flex;
  justify-content: center;
}
.logoContainer {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logoText {
  text-align: left;
  font: normal normal normal 43px/52px Roboto;
  letter-spacing: 0px;
  color: #0eafd7;
  text-transform: uppercase;
  opacity: 1;
}
.logoTagLine {
  font: normal normal normal 20px/30px Poppins;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
  margin-top: 15px;
  margin-bottom: 5px;
}
.mobileNumber{
  color: var(--unnamed-color-10acdc);
  text-align: left;
  font: normal normal normal 16px/25px Poppins;
  letter-spacing: 0px;
  color: #10ACDC;
  opacity: 1;
}