.totalChapter {
  display: flex;
  align-items: flex-end;
  margin-bottom: 0px !important;
}
.divider {
  margin: 18px;
}
.district_container {
  display: flex;
  justify-content: center;
  padding-bottom: 8px;
  align-self: baseline;
}

.dist_btn {
  cursor: pointer;
  border: 1px solid silver;
  background-color: #f8f8f8;
  font-size: 1rem;
  padding: 0.5rem;
  outline: none;
  transition-duration: 0.3s;
  margin: 0;
  font-size: 10px;
}
.graphbox {
  margin: 15px;
  width: 48%;
}
.standardWiseBox {
  display: flex;
  justify-content: space-between;
}
.subjectBoxCtrl {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.dropdown {
  width: 20%;
}
.container {
  display: "flex" !important;
  flex-wrap: "wrap" !important;
}

.chpterStatBox{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000A;
  border-radius: 4px;
  opacity: 1;
  height: 133px;
}

.statText{
  text-align: left;
  font: normal normal 600 50px/76px Poppins;
  letter-spacing: 0px;
  color: #4D4F5C;
  opacity: 1;
}

.copySvg{
  font-size: 3.3rem !important;
}

.headerText{
  text-align: left;
  font: normal normal normal 28px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
  padding-left: 20px;
}

.chart{
  background: #fff;
}

.chartHead{
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.leftRoundedBtn{
  height: 30px;
  border-radius: 50px 0 0 50px;
  display: flex;
  align-items: center;
}

.rightRoundedBtn{
  height: 30px;
  border-radius: 0 50px 50px 0;
  display: flex;
  align-items: center;
}

.btnText{
  text-align: left;
  font: normal normal normal 14px/20px Roboto;
  letter-spacing: 0px;
}