.courtesyContainer {
  position: sticky;
  position: -webkit-sticky;
  top: 55px;
  z-index: 100;
}
.tabBar {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 196px;
  z-index: 10;
}
.toolbarWrapper {
  display: "flex";
  flex-direction: "column";
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 244px;
  z-index: 10;
}
.actionHeader {
  position: sticky !important;
  position: -webkit-sticky !important;
  top: 0px;
  z-index: 10;
  padding-top: 14px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  background-color: #eeeeee;
}
.saveContainer {
  padding-bottom: 17px;
  background-color: #fff;
}
.saveBtn {
  margin-left: 23px !important;
  margin-top: 15px;
}
.label {
  font-size: 12px;
  color: gray;
}
.partField {
  background: white;
  border: 3px solid black;
  border-radius: 4px;
  margin-top: 15px;
}
.deletePartContainer {
  display: flex;
  justify-content: space-between;
  margin-right: 50px;
}
.deletePartBtn {
  text-transform: capitalize !important;
}
.preview {
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin: 5px 15px;
  border: 1px solid gray;
  padding: 10px;
}

.item_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.drag_handle {
  border: 1px solid gray;
  display: flex;
  padding: 8px;
  border-radius: 5px;
}
.audioWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.audioPlayer {
  margin: 0px 36px;
  display: flex;
  justify-content: center;
}
.record {
  margin-right: 20px;
  width: 50px;
  height: 50px;
  border: 1px solid gray;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.editPartBtn{
  float: right;
  margin: 15px 25px !important;
}
.editTagBtn{
  margin: 15px 25px !important;
}


.chapterDetail span{
  padding-right: 10px;
}

.chapterTitle{
  text-align: left;
  font: normal normal normal 22px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 1;
}

.subjectDetail{
  text-align: left;
  font: normal normal normal 18px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
  opacity: 0.7;
}

.otherChapDetail{
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
}

.detailTitle{
  text-align: left;
  font: normal normal bold 16px/40px Roboto;
  letter-spacing: 0px;
  color: #43425D;
}

.headerTitle{
  text-align: left;
font: normal normal normal 28px/40px Roboto;
letter-spacing: 0px;
color: #43425D;
opacity: 1;
}

.reviewCls{
  border-radius: 4px !important;
  opacity: 1 !important;
  height: 50px !important;
}

.changeStatusBtn{
background: #3B86FF 0% 0% no-repeat padding-box !important;
border-radius: 4px !important;
opacity: 1 !important;
color: #fff !important;
margin-left: 10px !important;
height: 50px !important;
}

.addWidgetDiv{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px dashed #707070;
  opacity: 1;
  width: 71vw;
  height: 154px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin: 10px;
  cursor: pointer;
}
.addWidgetBtn{
  position: relative !important;
  z-index: 1 !important;
  display: none !important;
  align-items: center !important;
  justify-content: center !important;
  left: 25vw;
}

.widgets:hover > .addWidgetBtn{
  display: inline-block !important;
}
.item_actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.published{
  background: #D0EE7C 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.inProgress{
  background: #FF987E 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.inReview{
  background: #FFD358 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.changesRequired{
  background: #FFCC80 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  opacity: 1 !important;
}

.break-before {
  page-break-before: always;
}

.break-after {
  page-break-after: always;
}

.break-avoid {
  page-break-inside: avoid;
}

strong{
  font-weight: 700;
}
@media screen and (max-width: 959px) {
  .cardContent
  {
    margin: auto;
  }

  .modalGridOuter
  {
    width: 70%;
  }
}

@media screen and (max-width: 480px) {
  .modalGridOuter
  {
    width: 90%;
  }
}

@media screen and (max-width: 600px) {
  .cardContent
  {
    width: 100% !important;
  }
}

.tableStyle
{
   margin-top: 1.5em; 
}
.tableStyle td
{ 
  margin-top: 1.5em;
   border-color: inherit;
    border-width: 1px;
 }